body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.action-card-inner {
	color: "#fff";
	justify-content: "center";
	align-items: "center";
	flex-direction: "column";
	display: "flex";
}

.action-card:hover {
	background: #efefef;
}

.action-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 15px;
	margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
	.action-wrapper {
		flex-direction: column;
	}
}

@media screen and (max-width: 600px) {
	.mobile-hide {
		display: none;
	}
}

/* FORMS */
.form-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 12px;
}

.form-wrapper-horizontal {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.form-column {
	width: 700px;
	padding: 5px;
	flex-grow: 1;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 15px;
	margin-bottom: 20px;
}

.form-column-narrow {
	padding: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 15px;
	margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
	.form-wrapper {
		flex-direction: column;
	}
}

@media screen and (max-width: 800px) {
	.form-wrapper {
		flex-direction: column;
	}
}

.form-row {
	width: 100%;
}

.form-row-right {
	display: flex;
	justify-content: flex-end;
}

.form-field {
	width: 100%;
}

.form-wrapper-narrow .form-field input {
	padding: 10px;
}

.form-wrapper-narrow .form-field label {
	line-height: 1em;
}

.vertical-table {
	margin-bottom: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
